/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Button, Badge } from "react-bootstrap";
import { toast } from "react-hot-toast";
import PageTitle from "../../../layouts/PageTitle";
import UnitModal from "./UnitModal";
import DocumentModal from "./DocumentModal";
import {
  DocumentFormValidate,
  onSubmitClicked,
  UnitFormValidate,
} from "../Action/AddNewPropertyFunctions";

const AddNewProperty = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalCentered, setModalCentered] = useState(false);
  const [documentModalCentered, setDocumentModalCentered] = useState(false);

  const [unitName, setUnitName] = useState("");
  const [unitType, setUnitType] = useState("");
  const [unitDescription, setUnitDescription] = useState("");
  const [editingUnitIndex, setEditingUnitIndex] = useState(null);

  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [documentFile, setDocumentFile] = useState();
  const [document, setDocument] = useState([]);
  const [editingDocumentIndex, setEditingDocumentIndex] = useState(null);
  const [documentErrors, setDocumentErrors] = useState({});

  const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState({});

  const [PropertyData, setPropertyData] = useState({});

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [documentPreview, setDocumentPreview] = useState("");

  const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

  const validDocumentTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
  ];
  const validateFileTypes = (files) => {
    const isValid = files.every((file) => validImageTypes.includes(file.type));
    if (!isValid) {
      toast.error("Some files are not valid images.");
    }
    return isValid;
  };

  // Image drop handler
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (!validateFileTypes(acceptedFiles)) return;

    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    const newPreviews = acceptedFiles.map((file) => URL.createObjectURL(file));
    setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

    fileRejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        toast.error(`Error for ${rejection.file.name}: ${error.message}`);
      });
    });
  }, []);

  const removeImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  // Document drop handler
  const onDocumentDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length) {
      const selectedFile = acceptedFiles[0];
      setDocumentFile(selectedFile);
      setDocumentPreview(URL.createObjectURL(selectedFile));
    }
    fileRejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        toast.error(`Error: ${error.message}`);
      });
    });
  }, []);

  useEffect(() => {
    return () => {
      previews.forEach((preview) => {
        URL.revokeObjectURL(preview);
      });
    };
  }, [previews]);

  // Dropzone for images
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: "image/jpeg, image/png, image/gif",
    multiple: true,
  });

  // Dropzone for documents
  const {
    getRootProps: getDocRootProps,
    getInputProps: getDocInputProps,
    isDragActive: isDocDragActive,
  } = useDropzone({
    onDrop: onDocumentDrop,
    multiple: false,
  });

  //validation for unit
  const validateForm = UnitFormValidate(
    unitName,
    unitType,
    unitDescription,
    setErrors
  );

  const validateDocumentForm = DocumentFormValidate(
    documentName,
    documentDescription,
    documentFile,
    validDocumentTypes,
    setDocumentErrors
  );
  //handle submit of unit
  const handleAddUnit = () => {
    if (validateForm()) {
      const newUnit = {
        name: unitName,
        type: unitType,
        description: unitDescription,
      };
      if (editingUnitIndex !== null) {
        // Edit existing unit
        setUnits((prevUnits) => {
          const updatedUnits = [...prevUnits];
          updatedUnits[editingUnitIndex] = newUnit;
          return updatedUnits;
        });
        setEditingUnitIndex(null);
      } else {
        // Add new unit
        setUnits((prevUnits) => [...prevUnits, newUnit]);
      }

      setUnitName("");
      setUnitType("");
      setUnitDescription("");
      setModalCentered(false);
    }
  };
  //
  const handleAddDocument = () => {
    if (validateDocumentForm()) {
      const newDocument = {
        label: documentName,
        description: documentDescription,
        file: documentFile,
      };
      if (editingDocumentIndex !== null) {
        // Edit existing document
        setDocument((prevDocuments) => {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments[editingDocumentIndex] = newDocument;
          return updatedDocuments;
        });
        setEditingDocumentIndex(null);
      } else {
        // Add new document
        setDocument((prevDocuments) => [...prevDocuments, newDocument]);
      }

      setDocumentName("");
      setDocumentDescription("");
      setDocumentFile(null);
      setDocumentPreview("");

      setDocumentModalCentered(false);
    }
  };

  const removeDocumentByIndex = (index) => {
    setDocument((prevDocuments) => [
      ...prevDocuments.slice(0, index),
      ...prevDocuments.slice(index + 1),
    ]);
  };

  const removeUnitByIndex = (index) => {
    setUnits((prevUnits) => [
      ...prevUnits.slice(0, index),
      ...prevUnits.slice(index + 1),
    ]);
  };

  const onSubmit = onSubmitClicked(
    setIsLoading,
    PropertyData,
    files,
    validImageTypes,
    document,
    units,
    nav,
    files
  );

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu={"Add property"}
        motherMenu={"Property List"}
        motherPath={"property-list"}
      />

      {UnitModal(
        modalCentered,
        setModalCentered,
        unitName,
        setUnitName,
        setUnitType,
        unitDescription,
        setUnitDescription,
        handleAddUnit,
        errors,
        setErrors,
        unitType
      )}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Property</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property name</label>
                    <input
                      type="text"
                      value={
                        PropertyData.property_name
                          ? PropertyData.property_name
                          : ""
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          property_name: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Property name"
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property number</label>
                    <input
                      value={
                        PropertyData.property_number
                          ? PropertyData.property_number
                          : ""
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          property_number: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Property number"
                      required=""
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property Type</label>
                    <input
                      value={PropertyData.type ? PropertyData.type : ""}
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          type: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="office,villa,apartment"
                      required=""
                    />
                  </div>

                  <div>
                    <label className="form-label">Rooms</label>

                    <div className="mb-3 col-12">
                      <Button
                        className="me-2  "
                        variant="success btn-rounded"
                        onClick={() => {
                          setEditingUnitIndex(null);
                          setModalCentered(true);
                        }}
                      >
                        <span className="btn-icon-start text-success ">
                          <i className="fa fa-plus color-success " />
                        </span>
                        Add
                      </Button>
                    </div>
                  </div>

                  <div className="col-12 mb-3 ">
                    {units.map((unit, index) => (
                      <Badge
                        key={index}
                        bg="badge-rounded"
                        className="badge-outline-dark px-4 py-1 h-100 fs-14 d-inline-flex gap-3 justify-content-between align-content-center "
                      >
                        <span
                          className="c-pointer fs-18 h-100 d-inline-flex justify-content-center align-items-center"
                          onClick={() => {
                            setUnitName(unit.name);
                            setUnitType(unit.type);
                            setUnitDescription(unit.description);
                            setEditingUnitIndex(index);
                            setModalCentered(true);
                          }}
                        >
                          {unit.name} - {unit.type}{" "}
                        </span>
                        <span
                          onClick={() => removeUnitByIndex(index)}
                          variant=" "
                          className=" btn-close p-0 m-0 fs-18 cursor-pointer  h-100 d-inline-flex justify-content-center align-items-center "
                        ></span>
                      </Badge>
                    ))}
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label className="form-label">Location</label>
                    <input
                      value={PropertyData.location ? PropertyData.location : ""}
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          location: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Location of your property"
                    />
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label className="form-label">Location link</label>
                    <input
                      value={
                        PropertyData.location_link
                          ? PropertyData.location_link
                          : ""
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          location_link: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Add link of your location from maps"
                    />
                  </div>
                  <div className="mb-3 col-sm-4">
                    <label className="form-label">City</label>
                    <input
                      value={PropertyData.city ? PropertyData.city : ""}
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          city: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Enter your city"
                      required=""
                    />
                  </div>

                  <div className="mb-3 col-sm-4">
                    <label className="form-label">Total value</label>
                    <input
                      value={
                        PropertyData.total_value
                          ? PropertyData.total_value
                          : null
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          total_value: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      placeholder="Total value of your property"
                    />
                  </div>
                  <div className="mb-3 col-sm-4">
                    <label className="form-label">Return percentage</label>
                    <input
                      value={
                        PropertyData.return_percentage
                          ? PropertyData.return_percentage
                          : null
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          return_percentage: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      placeholder="Annual return percentage of your property"
                    />
                  </div>
                </div>
              </form>
              <label className="form-label">Images</label>
              <div {...getRootProps()} className="drop-zone">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the images here...</p>
                ) : (
                  <p>Drag 'n' drop images here, or click to select images</p>
                )}
                {/* Image Preview Section */}
              </div>

              {previews.length > 0 && (
                <div>
                  <p>Previews:</p>
                  {previews.map((preview, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                      <span
                        className=" btn-close text-danger "
                        onClick={() => removeImage(index)}
                        style={{
                          fontSize: "18px",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                        }}
                      ></span>
                    </div>
                  ))}
                </div>
              )}

              <label className="form-label pt-3">Documents</label>

              <div className="mb-3 col-12">
                <Button
                  className="me-2  "
                  variant="success btn-rounded"
                  onClick={() => {
                    setEditingDocumentIndex(null);
                    setDocumentModalCentered(true);
                  }}
                >
                  <span className="btn-icon-start text-success ">
                    <i className="fa fa-plus color-success " />
                  </span>
                  Add
                </Button>
              </div>

              {DocumentModal(
                documentModalCentered,
                setDocumentModalCentered,
                documentName,
                setDocumentName,
                documentDescription,
                setDocumentDescription,
                documentFile,
                getDocRootProps,
                getDocInputProps,
                isDocDragActive,
                documentPreview,
                setDocumentFile,
                handleAddDocument,
                documentErrors,
                setDocumentErrors
              )}

              <div className="col-12 mb-3 ">
                {document?.map((doc, index) => (
                  <Badge
                    bg="badge-rounded"
                    className="badge-outline-dark px-4 py-1 h-100 fs-14 d-inline-flex gap-3 justify-content-between align-content-center "
                  >
                    <span
                      className="c-pointer fs-18 h-100 d-inline-flex justify-content-center align-items-center"
                      onClick={() => {
                        setDocumentName(doc.label);
                        setDocumentDescription(doc.description);
                        setDocumentFile(doc.file);
                        setEditingDocumentIndex(index);
                        setDocumentModalCentered(true);
                      }}
                    >
                      {doc.label}
                    </span>
                    <span
                      onClick={() => removeDocumentByIndex(index)}
                      variant=" "
                      className=" btn-close p-0 m-0 fs-18 cursor-pointer  h-100 d-inline-flex justify-content-center align-items-center "
                    ></span>
                  </Badge>
                ))}
              </div>

              <form>
                <div className="row">
                  <div className="col-sm-12 pt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-2"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger light"
                      onClick={() => nav("/property-list")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewProperty;
