import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Card, Tab, Nav } from "react-bootstrap";
import noImage from "../../../images/no-image.jpg";
import { getPropertyTransactions } from "../../../services/PropertyService";

const tabData = [
  {
    name: "Income",
  },
  {
    name: "Expense",
  },
];

const PropertyTransaction = () => {
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];

  const [transactionsList, setTransactionsList] = useState({
    data: [],
    total_documents: 0,
  });
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("Income");

  console.log(transactionsList);

  const totalPages = Math.ceil(transactionsList?.total_documents / 12);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await getPropertyTransactions(propertyId, pageNo);
        setTransactionsList(response.data);
      } catch (error) {
        console.error("Error fetching supervisor transactions:", error);
      } finally {
        setIsTabLoading(false);
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [pageNo, propertyId]);

  const handlePageChange = (isPrev) => {
    setIsTabLoading(true);
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onTabChange = (eventKey) => {
    // setIsTabLoading(true);
    setCurrentTab(eventKey);
  };

  const filteredTransactions = transactionsList.data.filter(
    (transaction) => transaction.type.toLowerCase() === currentTab.toLowerCase()
  );

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <Col xl={12}>
          <Card>
            <Card.Header>
              <Card.Title>Property Transaction Listing</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="custom-tab-1 h-100">
                <Tab.Container defaultActiveKey={tabData[0].name}>
                  <Nav as="ul" className="nav-tabs justify-content-center">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          eventKey={data.name}
                          onClick={() => onTabChange(data.name)}
                        >
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <div className="row pt-4">
                    {isTabLoading ? (
                      <div className="h-auto w-100 justify-content-center align-items-center">
                        <div className="sk-three-bounce">
                          <div className="sk-child sk-bounce1"></div>
                          <div className="sk-child sk-bounce2"></div>
                          <div className="sk-child sk-bounce3"></div>
                        </div>
                      </div>
                    ) : (
                      filteredTransactions.map((transaction, index) => (
                        <div
                          className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6"
                          key={index}
                        >
                          <div className="card project-boxed box-shadow-lg">
                            <div className="img-bx h-100">
                              <img
                                src={
                                  transaction.images &&
                                  transaction.images.length > 0
                                    ? transaction.images[0]
                                    : noImage
                                }
                                alt=""
                                className="me-3 object-fit-fill w-100 h-100"
                              />
                            </div>

                            <div className="card-header align-items-start">
                              <div>
                                <p className="fs-14 mb-2 text-primary text-uppercase">
                                  {transaction?.type}
                                </p>
                                <h6 className="fs-18 font-w500 mb-3">
                                  {/* <Link
                                    to={"#"}
                                    className={`${
                                      transaction?.property?.property_name
                                        ?.length > 0
                                        ? "text-black"
                                        : "text-muted"
                                    } user-name`}
                                  >
                                    {transaction?.property?.property_name
                                      ?.length > 0
                                      ? transaction?.property?.property_name
                                      : "Property details not available"}
                                  </Link> */}
                                </h6>
                                <div className="text-dark fs-14 text-nowrap">
                                  <i
                                    className="fas fa-calendar me-3"
                                    aria-hidden="true"
                                  ></i>
                                  Created on{" "}
                                  {transaction?.created_at
                                    ? new Date(
                                        transaction.created_at
                                      ).toUTCString()
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="card-body p-0 pb-3">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <span className="mb-0 title">Currency</span> :
                                  <span className="text-black ms-2">
                                    {transaction?.country?.currency_symbol}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">Amount</span> :
                                  <span className="text-black ms-2">
                                    {transaction?.amount}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">
                                    Description
                                  </span>{" "}
                                  :
                                  <span className="text-black desc-text ms-2">
                                    {transaction?.description}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab.Container>
              </div>
            </Card.Body>
            <div className="row container-fluid">
              <div className="col-xl-12">
                <div className="table-responsive table-hover fs-14 order-list-table">
                  <div id="orderList" className="dataTables_wrapper no-footer">
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {filteredTransactions?.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <div
                          className={"paginate_button previous "}
                          onClick={() => {
                            if (pageNo > 1) {
                              handlePageChange(true);
                            }
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                        </div>
                        <span>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <div
                              key={i}
                              className={`paginate_button ${
                                pageNo === i + 1 ? "current" : ""
                              }`}
                            >
                              {i + 1}
                            </div>
                          ))}
                        </span>
                        <div
                          className="paginate_button next"
                          onClick={() => {
                            if (totalPages > pageNo) {
                              handlePageChange(false);
                            }
                          }}
                        >
                          <i className="fa fa-angle-double-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default PropertyTransaction;
