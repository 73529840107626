/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import { UsersListAction } from "../../../store/actions/UsersAction";
import { onRequestExport } from "../../../services/ExportExcelService";
import DateRangePicker from "react-bootstrap-daterangepicker";

import { Button, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const typeOptions = [
  { value: "all", label: "Income & Expense" },
  { value: "expense", label: "Expense" },
  { value: "income", label: "Income" },
  { value: "rent", label: "Rent" },
  { value: "initial", label: "Initial expense" },
];

const formatDate = (date) => {
  const d = new Date(date);
  let day = "" + d.getDate();
  let month = "" + (d.getMonth() + 1);
  const year = d.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [day, month, year].join("/");
};

const Export = () => {
  const { properties } = useSelector((state) => state.properties);
  const { usersList } = useSelector((state) => state.users);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedType, setSelectedType] = useState(typeOptions[0]);
  const [dateRange, setDateRange] = useState({
    startDate: formatDate(new Date()), // Initial start date as today's date
    endDate: formatDate(new Date()), // Initial end date as today's date
  });
  const [isDateRangeDisabled, setIsDateRangeDisabled] = useState(false);
  const [isUserDisabled, setIsUserDisabled] = useState(false);

  const [exportFormat, setExportFormat] = useState("excel");
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    let modalData = [];

    if (selectedType.value === "all") {
      modalData.push({
        type: "income",
        data: incomeFormattedContent,
        total: incomeTotal,
      });
      modalData.push({
        type: "expense",
        data: expenseFormattedContent,
        total: expenseTotal,
      });
    } else if (selectedType.value === "income") {
      modalData.push({
        type: "income",
        data: incomeFormattedContent,
        total: incomeTotal,
      });
    } else if (["expense", "rent"].includes(selectedType.value)) {
      modalData.push({
        type: "expense",
        data: expenseFormattedContent,
        total: expenseTotal,
      });
    } else if (selectedType.value === "initial") {
      modalData.push({
        type: "initial",
        data: initialFormattedContent,
        total: initialTotal,
      });
    }

    setModalDataState(modalData);

    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  //state for tables

  // const [incomeColumnOrder, setIncomeColumnOrder] = useState([]);
  // const [expenseColumnOrder, setExpenseColumnOrder] = useState([]);
  // const [initialColumnOrder, setInitialColumnOrder] = useState([]);

  const [incomeTotal, setIncomeTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [initialTotal, setInitialTotal] = useState(0);

  const [incomeFormattedContent, setIncomeFormattedContent] = useState([]);
  const [expenseFormattedContent, setExpenseFormattedContent] = useState([]);
  const [initialFormattedContent, setInitialFormattedContent] = useState([]);

  const [modalDataState, setModalDataState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PropertyAction("all"));
    dispatch(UsersListAction("all"));
  }, [dispatch]);

  useEffect(() => {
    const propertyOptions = properties?.data?.map((property) => ({
      value: property?._id,
      label: property?.property_name,
    }));
    setFilteredProperties(propertyOptions);
  }, [properties]);

  useEffect(() => {
    const userOptions = usersList?.users?.map((user) => ({
      value: user?.user?._id,
      label: user?.user?.name,
    }));
    setFilteredUsers(userOptions);
  }, [usersList]);

  useEffect(() => {
    if (selectedProperty) {
      setFilteredUsers(
        usersList?.users
          .filter((user) =>
            user?.properties?.some(
              (property) => property?._id === selectedProperty?.value
            )
          )
          .map((user) => ({
            value: user?.user?._id,
            label: user?.user?.name,
          }))
      );
    } else {
      setFilteredUsers(
        usersList?.users?.map((user) => ({
          value: user?.user?._id,
          label: user?.user?.name,
        }))
      );
    }
  }, [selectedProperty, usersList]);

  useEffect(() => {
    if (selectedUser.length > 0) {
      setFilteredProperties(
        properties?.data
          .filter((property) =>
            property?.users?.some((user) =>
              selectedUser.some((selected) => selected.value === user?._id)
            )
          )
          .map((property) => ({
            value: property?._id,
            label: property?.property_name,
          }))
      );
    } else {
      setFilteredProperties(
        properties?.data?.map((property) => ({
          value: property?._id,
          label: property?.property_name,
        }))
      );
    }
  }, [selectedUser, properties]);

  const handleSelectProperty = (selectedOption) => {
    setSelectedProperty(selectedOption);
    setShowDownloadButton(false);
  };

  const handleSelectUser = (selectedOptions) => {
    setSelectedUser(selectedOptions);
    setShowDownloadButton(false);
  };

  const handleSelectType = (selectedOption) => {
    setSelectedType(selectedOption);
    setShowDownloadButton(false);
  };

  const handleCheckboxChange = (event) => {
    setDateRange((prevState) => ({
      ...prevState,
      all: event.target.checked,
    }));
    setIsDateRangeDisabled(event.target.checked);
    setShowDownloadButton(false);
  };

  const handleUserCheckboxChange = (event) => {
    setSelectedUser([]);
    setIsUserDisabled(event.target.checked);
    console.log(isUserDisabled);
    setShowDownloadButton(false);
  };

  const handleDateRangeChange = (start, end) => {
    setDateRange({
      startDate: formatDate(start._d),
      endDate: formatDate(end._d),
    });
    setShowDownloadButton(false);
  };

  const validateForm = () => {
    if (!selectedType) {
      toast.error("Type is required");
      return false;
    }
    if (!selectedProperty && !selectedUser) {
      toast.error("Please select at least one of Property or User");
      return false;
    }
    return true;
  };

  const onSubmitRequestExport = async () => {
    if (!validateForm()) return;

    const queryParams = {
      type: selectedType.value,
      ...(selectedUser.length > 0 && {
        user: selectedUser.map((user) => user.value),
      }),
      ...(selectedProperty && { property: selectedProperty.value }),
    };

    if (!dateRange.all) {
      queryParams.date_range = `${dateRange.startDate}-${dateRange.endDate}`;
    }

    try {
      const res = await onRequestExport(queryParams);

      console.log(res.data);

      const { incomeTransactions, expenseTransactions, transactionData } =
        res.data;

      // const commonColumns = [
      //   { label: "No.", value: "No" },
      //   { label: "Voucher Number", value: "voucher_number" },
      //   { label: "Date", value: "date" },
      //   { label: "Description", value: "description" },
      //   { label: "User", value: "user" },
      //   { label: "Amount", value: "amount" },
      // ];

      const formatTransactionData = (transactions) =>
        transactions.map((row, index) => ({
          No: index + 1,
          voucher_number: row.voucher_number || "",
          date: row.date || "",
          description: row.description || "",
          user: row.user || "",
          amount: row.amount || "",
        }));

      if (selectedType.value === "all") {
        if (incomeTransactions?.length > 0 || expenseTransactions?.length > 0) {
          setIncomeFormattedContent(formatTransactionData(incomeTransactions));
          setExpenseFormattedContent(
            formatTransactionData(expenseTransactions)
          );
          // setIncomeColumnOrder(commonColumns);
          // setExpenseColumnOrder(commonColumns);
          setIncomeTotal(res.data.totalIncome);
          setExpenseTotal(res.data.totalExpense);
          setIsDataAvailable(true);
        } else {
          setIsDataAvailable(false);
          toast.error("No data found");
        }
      } else {
        const formattedData = formatTransactionData(transactionData);
        if (transactionData.length > 0) {
          setIsDataAvailable(true);
          if (selectedType.value === "income") {
            setIncomeFormattedContent(formattedData);
            // setIncomeColumnOrder(commonColumns);
            setIncomeTotal(res.data.totalIncome);
          } else if (["expense", "rent"].includes(selectedType.value)) {
            setExpenseFormattedContent(formattedData);
            // setExpenseColumnOrder(commonColumns);
            setExpenseTotal(res.data.totalExpense);
          } else if (selectedType.value === "initial") {
            setInitialFormattedContent(formattedData);
            // setInitialColumnOrder(commonColumns);
            setInitialTotal(res.data.totalInitial);
          }
        } else {
          setIsDataAvailable(false);
          toast.error("No data found");
        }
      }

      setShowDownloadButton(true);
    } catch (error) {
      console.error("Error during export:", error);
      setShowDownloadButton(false);
      toast.error("Failed to export data");
    }
  };

  const onDownloadExport = async () => {
    if (exportFormat === "excel") {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Transaction Data");

      // Define columns
      const columns = [
        {
          header: "No.",
          key: "No",
          width: 10,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          header: "Voucher Number",
          key: "voucher_number",
          width: 20,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          header: "Date",
          key: "date",
          width: 15,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          header: "Description",
          key: "description",
          width: 30,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          header: "User",
          key: "user",
          width: 20,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          header: "Amount",
          key: "amount",
          width: 15,
          style: {
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          },
        },
      ];

      sheet.columns = columns;

      sheet.getRow(1).eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      const alignCellsCenter = (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
          };
        });
      };

      const getMaxColumnWidth = (data, columns) => {
        const widths = columns.reduce((acc, col) => {
          acc[col.key] = col.header.length;
          return acc;
        }, {});

        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (row[key]) {
              const cellLength = row[key].toString().length;
              if (cellLength > (widths[key] || 0)) {
                widths[key] = cellLength;
              }
            }
          });
        });

        return widths;
      };

      const setColumnWidths = (widths) => {
        sheet.columns.forEach((col) => {
          col.width = Math.max(widths[col.key] + 2, col.width); // Adjust width to fit content
        });
      };

      if (selectedType.value === "all") {
        // Add Expense Transactions
        if (expenseFormattedContent.length > 0) {
          const headerRow = sheet.addRow([]);
          headerRow.getCell(1).value = "Expense Transactions";
          headerRow.font = { bold: true };
          headerRow.height = 30; // Increase row height
          headerRow.alignment = { vertical: "middle", horizontal: "center" }; // Center text vertically and horizontally
          sheet.mergeCells(`A${headerRow.number}:F${headerRow.number}`); // Merge cells for full-width header

          expenseFormattedContent.forEach((row, index) => {
            const dataRow = sheet.addRow({
              No: index + 1,
              ...row,
            });
            alignCellsCenter(dataRow);
          });
          const totalRow = sheet.addRow({
            No: "",
            voucher_number: "",
            date: "",
            description: "",
            user: "Total",
            amount: `${expenseTotal}`,
          });
          totalRow.font = { bold: true };
          alignCellsCenter(totalRow);

          // Adjust column widths
          const widths = getMaxColumnWidth(expenseFormattedContent, columns);
          setColumnWidths(widths);
        }

        // Add Income Transactions
        if (incomeFormattedContent.length > 0) {
          const headerRow = sheet.addRow([]);
          headerRow.getCell(1).value = "Income Transactions";
          headerRow.font = { bold: true };
          headerRow.height = 30; // Increase row height
          headerRow.alignment = { vertical: "middle", horizontal: "center" }; // Center text vertically and horizontally
          sheet.mergeCells(`A${headerRow.number}:F${headerRow.number}`); // Merge cells for full-width header

          incomeFormattedContent.forEach((row, index) => {
            const dataRow = sheet.addRow({
              No: index + 1,
              ...row,
            });
            alignCellsCenter(dataRow);
          });
          const totalRow = sheet.addRow({
            No: "",
            voucher_number: "",
            date: "",
            description: "",
            user: "Total",
            amount: `${incomeTotal}`,
          });
          totalRow.font = { bold: true };
          alignCellsCenter(totalRow);

          // Adjust column widths
          const widths = getMaxColumnWidth(incomeFormattedContent, columns);
          setColumnWidths(widths);
        }
      } else {
        // For specific type
        const dataToExport =
          selectedType.value === "income"
            ? incomeFormattedContent
            : selectedType.value === "initial"
            ? initialFormattedContent
            : expenseFormattedContent;

        // const headerRow = sheet.addRow([]);
        // headerRow.getCell(1).value = `${
        //   selectedType.value.charAt(0).toUpperCase() +
        //   selectedType.value.slice(1)
        // } Transactions`;
        // headerRow.font = { bold: true };
        // headerRow.height = 30;
        // headerRow.alignment = { vertical: "middle", horizontal: "center" };
        // sheet.mergeCells(`A${headerRow.number}:F${headerRow.number}`);

        dataToExport.forEach((row, index) => {
          const dataRow = sheet.addRow({
            No: index + 1,
            ...row,
          });
          alignCellsCenter(dataRow);
        });

        const totalRow = sheet.addRow({
          No: "",
          voucher_number: "",
          date: "",
          description: "",
          user: "Total",
          amount: `${
            selectedType.value === "income"
              ? incomeTotal
              : selectedType.value === "initial"
              ? initialTotal
              : expenseTotal
          }`,
        });
        totalRow.font = { bold: true };
        alignCellsCenter(totalRow);

        // Adjust column widths
        const widths = getMaxColumnWidth(dataToExport, columns);
        setColumnWidths(widths);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Report ${new Date().toLocaleDateString()}.xlsx`;
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (exportFormat === "pdf") {
      const doc = new jsPDF();

      const addTableWithTotal = (title, data) => {
        const totalAmount = data
          .reduce((sum, row) => sum + parseFloat(row.amount || 0), 0)
          .toFixed(2);

        const columnWidths = [20, 30, 50, 30, 30]; // Adjust widths as needed
        const headers = [
          "No.",
          "Voucher Number",
          "Date",
          "Description",
          "User",
          "Amount",
        ];

        // Set title before the table
        doc.setFontSize(16);
        doc.setFont("Helvetica", "bold");

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = pageWidth / 2;
        const titleY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 20 : 20;

        doc.text(title, titleX, titleY, { align: "center" });

        // Reset font size and style for the table
        doc.setFontSize(10);
        doc.setFont("Helvetica", "normal");

        doc.autoTable({
          head: [headers],
          body: data.map((row, index) => [
            index + 1,
            row.voucher_number,
            row.date,
            row.description,
            row.user,
            row.amount,
          ]),
          columnStyles: {
            0: { cellWidth: columnWidths[0] }, // No.
            1: { cellWidth: columnWidths[1] }, // Voucher Number
            2: { cellWidth: columnWidths[2] }, // Date
            3: { cellWidth: columnWidths[3] }, // Description
            4: { cellWidth: columnWidths[4] }, // User
            5: { cellWidth: columnWidths[5] }, // Amount
          },
          theme: "striped",
          foot: [["", "", "", "", "Total Amount", totalAmount]],
          startY: titleY + 10, // Start table below the title
        });

        return doc.lastAutoTable.finalY + 20;
      };

      if (selectedType.value === "all") {
        if (expenseFormattedContent.length > 0) {
          addTableWithTotal(
            "Expense Transactions",
            expenseFormattedContent
            // yOffset
          );
        }

        if (incomeFormattedContent.length > 0) {
          addTableWithTotal(
            "Income Transactions",
            incomeFormattedContent
            // yOffset
          );
        }
      } else {
        const dataToExport =
          selectedType.value === "income"
            ? incomeFormattedContent
            : selectedType.value === "initial"
            ? initialFormattedContent
            : expenseFormattedContent;

        addTableWithTotal(
          `${
            selectedType.value.charAt(0).toUpperCase() +
            selectedType.value.slice(1)
          } Transactions`,
          dataToExport,
          20 // Initial space for title and margin
        );
      }

      doc.save(`Report ${new Date().toLocaleDateString()}.pdf`);
    }
  };

  const handleExport = () => {
    // if (exportFormat === "pdf") {
    //   exportToPDF(exportData);
    // } else {
    //   exportToExcel(exportData);
    // }

    onDownloadExport();
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Report Form</h4>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="mb-3 col-lg-6 col-md-12">
                  <label className="form-label">Property Name</label>
                  <Select
                    className="basic-single"
                    placeholder="Search by Property Name"
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    options={filteredProperties}
                    onChange={handleSelectProperty}
                    value={selectedProperty}
                  />
                </div>

                <div className="mb-3 col-lg-6 col-md-12">
                  <label className="form-label">User</label>
                  <Select
                    options={filteredUsers}
                    value={selectedUser}
                    onChange={handleSelectUser}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Search by User Name"
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    isDisabled={isUserDisabled}
                    isMulti
                  ></Select>
                  <div className="d-flex mt-2 px-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleUserCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      All
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-6 col-md-12">
                  <label className="form-label">Type</label>
                  <Select
                    options={typeOptions}
                    value={selectedType}
                    className="basic-single  "
                    onChange={handleSelectType}
                    isSearchable={false}
                  />
                </div>

                <div className="mb-3 col-lg-6 col-md-12 ">
                  <label className="form-label">Date Range</label>
                  <DateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onApply={(event, picker) =>
                      handleDateRangeChange(picker.startDate, picker.endDate)
                    }
                    disabled={isDateRangeDisabled}
                  >
                    <input
                      type="text"
                      disabled={isDateRangeDisabled}
                      className={`form-control rounded-1 border-1  input-daterange-timepicker ${
                        isDateRangeDisabled && "bg-dark-subtle"
                      } `}
                    />
                  </DateRangePicker>
                  <div className="d-flex mt-2 px-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      All
                    </label>
                  </div>
                </div>

                <div className="mt-5 gap-5">
                  <div className="d-flex w-100 justify-content-center align-content-center gap-3">
                    <div className="d-flex justify-content-center align-content-center gap-1 ">
                      <input
                        type="radio"
                        id="excel"
                        name="exportFormat"
                        value="excel"
                        checked={exportFormat === "excel"}
                        onChange={() => setExportFormat("excel")}
                      />
                      <label htmlFor="excel">Export to Excel</label>
                    </div>
                    <div className="d-flex justify-content-center align-content-center gap-1 ">
                      <input
                        type="radio"
                        id="pdf"
                        name="exportFormat"
                        value="pdf"
                        checked={exportFormat === "pdf"}
                        onChange={() => setExportFormat("pdf")}
                      />
                      <label htmlFor="pdf">Export to PDF</label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-content-center">
                    {!showDownloadButton ? (
                      <Button
                        className="me-2 mt-3"
                        variant="success"
                        onClick={onSubmitRequestExport}
                      >
                        Export
                        <span className="btn-icon-end">
                          <i className="fa fa-download color-success"></i>
                        </span>
                      </Button>
                    ) : (
                      <div className="d-inline-flex gap-3">
                        <Button
                          className="me-2 mt-3"
                          variant={`success btn-rounded ${
                            isDataAvailable ? "" : "disabled"
                          }`}
                          onClick={handleShowModal}
                        >
                          <span className="btn-icon-start text-success">
                            <i className="fa fa-download color-success"></i>
                          </span>
                          {isDataAvailable ? "View" : "Nothing to view"}
                        </Button>

                        <Button
                          className="me-2 mt-3"
                          variant={`success btn-rounded ${
                            isDataAvailable ? "" : "disabled"
                          }`}
                          onClick={handleExport}
                        >
                          <span className="btn-icon-start text-success">
                            <i className="fa fa-download color-success"></i>
                          </span>
                          {isDataAvailable ? "Download" : "Nothing to Download"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Export Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-scroll">
              {isDataAvailable && modalDataState ? (
                modalDataState.map((tableData, index) => (
                  <div key={index}>
                    {tableData.type === "income" &&
                      tableData.data.length > 0 && (
                        <>
                          <h5>Income Transactions</h5>
                          <Table striped bordered hover size="xl">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Voucher Number</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>User</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>{row.No}</td>
                                  <td>{row.voucher_number}</td>
                                  <td>{row.date}</td>
                                  <td>{row.description}</td>
                                  <td>{row.user}</td>
                                  <td>{row.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="5" style={{ textAlign: "right" }}>
                                  Total Income
                                </td>
                                <td>{tableData.total}</td>
                              </tr>
                            </tfoot>
                          </Table>
                        </>
                      )}

                    {tableData.type === "expense" &&
                      tableData.data.length > 0 && (
                        <>
                          <h5>Expense Transactions</h5>
                          <Table striped bordered hover size="xl">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Voucher Number</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>User</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>{row.No}</td>
                                  <td>{row.voucher_number}</td>
                                  <td>{row.date}</td>
                                  <td>{row.description}</td>
                                  <td>{row.user}</td>
                                  <td>{row.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="5" style={{ textAlign: "right" }}>
                                  Total Expense
                                </td>
                                <td>{tableData.total}</td>
                              </tr>
                            </tfoot>
                          </Table>
                        </>
                      )}

                    {tableData.type === "initial" &&
                      tableData.data.length > 0 && (
                        <>
                          <h5>Initial Transactions</h5>
                          <Table striped bordered hover size="xl">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Voucher Number</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>User</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>{row.No}</td>
                                  <td>{row.voucher_number}</td>
                                  <td>{row.date}</td>
                                  <td>{row.description}</td>
                                  <td>{row.user}</td>
                                  <td>{row.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="5" style={{ textAlign: "right" }}>
                                  Total Initial
                                </td>
                                <td>{tableData.total}</td>
                              </tr>
                            </tfoot>
                          </Table>
                        </>
                      )}
                  </div>
                ))
              ) : (
                <p>No data available to display.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleExport(exportFormat);
                  handleCloseModal();
                }}
              >
                Download
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Export;
