import axiosInstance from "./AxiosInstance";

export function getAllUsers(role) {
  // console.log("role", role);
  return axiosInstance.get(
    `/api/users${role !== "all" ? `?role=${role}` : ""} `
  );
}

export function freezeUser(id) {
  return axiosInstance.get(`/api/users/status-change/${id}`);
}

export function getUserDetails(id) {
  return axiosInstance.get(`/api/users/${id}`);
}

export function AssignUserToProperty(postData) {
  return axiosInstance.post(`/api/users/assign-property`, postData);
}

export function editUser(id, data) {
  return axiosInstance.post(`/api/users/update/${id}`, data);
}

export function deleteUser(id, data) {
  return axiosInstance.get(`/api/users/delete/${id}`);
}

export function addNewUser(data) {
  return axiosInstance.post(`/api/users/create`, data);
}
