import axiosInstance from "./AxiosInstance";

export function OnRequestPropertyExport(propertyId) {
  return axiosInstance.get(
    `/api/property/excel-report?type=rent&property_id=${propertyId}`
  );
}

export function OnRequestTypeExport(type) {
  return axiosInstance.get(`/api/property/excel-report?type=${type}`);
}

export function onRequestExport(queryParams) {
  // console.log(queryParams); // Log queryParams to verify they are correct

  const query = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined && queryParams[key] !== "")
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  console.log(`/api/transactions/export?` + query); // Log the constructed query string to verify it

  return axiosInstance.get(`/api/transactions/export?${query}`);
}
