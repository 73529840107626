/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPropertyDetails } from "../../../../services/PropertyService";
import toast from "react-hot-toast";
import PageTitle from "../../../layouts/PageTitle";
import {
  DocumentFormValidate,
  UnitFormValidate,
} from "../Action/AddNewPropertyFunctions";
import { Badge, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { EditPropertyModal, OnSubmitEditProperty } from "./EditPropertyAction";

const EditPropertyDetails = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];
  const [PropertyData, setPropertyData] = useState();
  const [oldPropertyData, setOldPropertyData] = useState();

  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true
  const [modalCentered, setModalCentered] = useState(false);
  const [documentModalCentered, setDocumentModalCentered] = useState(false);

  const [unitIndex, setUnitIndex] = useState(null);
  const [unitName, setUnitName] = useState("");
  const [unitType, setUnitType] = useState("");
  const [unitDescription, setUnitDescription] = useState("");
  const [deletedUnits, setDeletedUnits] = useState([]);

  const [documentIndex, setDocumentIndex] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [documentFile, setDocumentFile] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentErrors, setDocumentErrors] = useState({});
  const [deletedDocument, setDeletedDocument] = useState([]);

  const [documentPreview, setDocumentPreview] = useState("");

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

  const validDocumentTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
    "application/msword",
    "application/xlsx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
  ];

  const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getPropertyDetails(propertyId)
      .then((res) => {
        const property = res.data.property;
        setPropertyData(property);
        setOldPropertyData(property);
        // console.log(property);
        setUnits(
          property.units.map((unit) => ({
            ...unit,
            isEdited: false,
            isNew: false,
          }))
        );
        setDocuments(
          property.documents.map((doc) => ({
            ...doc,
            isEdited: false,
            isNew: false,
          }))
        );
        const initialImages = property.images.map((img) => ({
          url: img,
          isNew: false,
          isDeleted: false,
        }));
        setFiles(initialImages);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [nav, propertyId]);

  //handleChange

  const handleChange = (value, field) => {
    setPropertyData((prev) => {
      if (prev[field] !== value) {
        return { ...prev, [field]: value };
      }
      return prev;
    });
  };

  const validateFileTypes = (files) => {
    const isValid = files.every((file) => validImageTypes.includes(file.type));
    if (!isValid) {
      toast.error("Some files are not valid images.");
    }
    return isValid;
  };

  // Image drop handler
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (!validateFileTypes(acceptedFiles)) return;

    const newFiles = acceptedFiles.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
      isNew: true,
      isDeleted: false,
    }));

    setFiles((prev) => [...prev, ...newFiles]);
    setPreviews((prev) => [...prev, ...newFiles.map((f) => f.url)]);

    fileRejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        toast.error(`Error for ${rejection.file.name}: ${error.message}`);
      });
    });
  }, []);

  const removeImage = (index) => {
    setFiles((prev) =>
      prev
        .map((file, idx) => {
          if (idx === index) {
            if (file.isNew) {
              return null;
            } else {
              return { ...file, isDeleted: !file.isDeleted };
            }
          }
          return file;
        })
        .filter((file) => file !== null)
    );

    setPreviews((prev) =>
      prev.filter((_, idx) => idx !== index || !files[index].isNew)
    );
  };

  useEffect(() => {
    return () => {
      previews.forEach((preview) => {
        URL.revokeObjectURL(preview.url);
      });
    };
  }, [previews]);

  // Dropzone for images
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: "image/jpeg, image/png, image/gif",
    multiple: true,
  });

  // Document drop handler

  const onDocumentDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length) {
      const selectedFile = acceptedFiles[0];
      setDocumentFile(selectedFile);
      setDocumentPreview(URL.createObjectURL(selectedFile));
    }
    fileRejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        toast.error(`Error: ${error.message}`);
      });
    });
  }, []);

  //DropZone

  const {
    getRootProps: getDocRootProps,
    getInputProps: getDocInputProps,
    isDragActive: isDocDragActive,
  } = useDropzone({
    onDrop: onDocumentDrop,
    multiple: false,
  });

  //validateUnitForm

  const validateForm = UnitFormValidate(
    unitName,
    unitType,
    unitDescription,
    setErrors
  );

  //validateDocumentForm

  const validateDocumentForm = DocumentFormValidate(
    documentName,
    documentDescription,
    documentFile,
    validDocumentTypes,
    setDocumentErrors,
    documentIndex
  );

  //handleAddDocument

  const handleAddDocument = () => {
    if (validateDocumentForm()) {
      if (documentIndex === null) {
        const newDocument = {
          label: documentName,
          description: documentDescription,
          file: documentFile,
          isNew: true,
        };
        setDocuments((prevDocuments) => [...prevDocuments, newDocument]);
      } else {
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc, idx) => {
            if (idx === documentIndex) {
              if (
                doc.label !== documentName ||
                doc.description !== documentDescription ||
                doc.document_link !== documentFile
              ) {
                return {
                  ...doc,
                  label: documentName,
                  description: documentDescription,
                  file: documentFile,
                  ...(!doc.isNew ? { isEdited: true } : {}),
                };
              }
            }
            return doc;
          })
        );
      }
      setDocumentModalCentered(false);
      setDocumentName("");
      setDocumentDescription("");
      setDocumentFile(null);
      setDocumentPreview("");
      setDocumentIndex(null);
    }
  };

  //handleRemoveDocument

  // const removeDocumentByIndex = (index) => {
  //   setDocument((prevDocuments) => [
  //     ...prevDocuments.slice(0, index),
  //     ...prevDocuments.slice(index + 1),
  //   ]);
  // };

  const removeDocumentByIndex = (index, id) => {
    setDocuments((prevDocuments) => [
      ...prevDocuments.slice(0, index),
      ...prevDocuments.slice(index + 1),
    ]);

    if (id) {
      setDeletedDocument((prevDocuments) => [
        ...prevDocuments,
        {
          ...documents[index],
          isDeleted: true,
        },
      ]);
    }
  };

  //handleAddUnit

  const handleAddUnit = () => {
    if (validateForm()) {
      // console.log("reached");
      if (unitIndex === null) {
        const newUnit = {
          name: unitName,
          type: unitType,
          description: unitDescription,
          isNew: true,
        };

        setUnits((prevUnits) => [...prevUnits, newUnit]);
      } else {
        setUnits((prevUnits) =>
          prevUnits.map((unit, idx) => {
            if (
              idx === unitIndex &&
              (unit.name !== unitName ||
                unit.type !== unitType ||
                unit.description !== unitDescription)
            ) {
              return {
                ...unit,
                name: unitName,
                type: unitType,
                description: unitDescription,
                ...(!unit.isNew ? { isEdited: true } : {}),
              };
            }
            return unit;
          })
        );
      }

      setUnitName("");
      setUnitType("");
      setUnitDescription("");
      setUnitIndex(null);
      setModalCentered(false);
    }
  };

  //handleRemoveUnit

  // const removeUnitByIndex = (index) => {
  //   setUnits((prevUnits) => [
  //     ...prevUnits.slice(0, index),
  //     ...prevUnits.slice(index + 1),
  //   ]);
  // };

  const removeUnitByIndex = (index, id) => {
    setUnits((prevUnits) => [
      ...prevUnits.slice(0, index),
      ...prevUnits.slice(index + 1),
    ]);

    if (id) {
      setDeletedUnits((prevUnits) => [
        ...prevUnits,
        {
          ...units[index],
          isDeleted: true,
        },
      ]);
    }
  };

  //onSubmit

  const onSubmit = OnSubmitEditProperty(
    setIsLoading,
    units,
    documents,
    deletedUnits,
    deletedDocument,
    oldPropertyData,
    PropertyData,
    nav,
    files
  );

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"Edit property"}
        activePath={"edit-property"}
        motherMenu={"Property List"}
        motherPath={"property-list"}
      />
      <div className="row">
        {EditPropertyModal(
          modalCentered,
          setModalCentered,
          unitName,
          setUnitName,
          errors,
          setUnitType,
          unitDescription,
          setUnitDescription,
          setErrors,
          handleAddUnit,
          documentModalCentered,
          setDocumentModalCentered,
          documentName,
          setDocumentName,
          documentErrors,
          documentDescription,
          setDocumentDescription,
          getDocRootProps,
          getDocInputProps,
          isDocDragActive,
          documentPreview,
          documentFile,
          setDocumentFile,
          setDocumentErrors,
          handleAddDocument,
          // documentIndex,
          setDocumentIndex,
          setDocumentPreview,
          setUnitIndex,
          unitType
        )}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Property</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property name</label>
                    <input
                      type="text"
                      value={PropertyData?.property_name}
                      onChange={(e) =>
                        handleChange(e.target.value, "property_name")
                      }
                      className="form-control"
                      placeholder="Property name"
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property number</label>
                    <input
                      type="text"
                      value={PropertyData?.property_number}
                      onChange={(e) =>
                        handleChange(e.target.value, "property_number")
                      }
                      className="form-control"
                      placeholder="Property number"
                    />
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Property Type</label>
                    <input
                      type="text"
                      value={PropertyData?.type}
                      onChange={(e) => handleChange(e.target.value, "type")}
                      className="form-control"
                      placeholder="Type of property (office, villa, apartment)"
                    />
                  </div>

                  <div>
                    <label className="form-label">Rooms</label>

                    <div className="mb-3 col-12">
                      <Button
                        className="  "
                        variant="success btn-rounded"
                        onClick={() => setModalCentered(true)}
                      >
                        <span className="btn-icon-start text-success ">
                          <i className="fa fa-plus color-success " />
                        </span>
                        Add
                      </Button>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    {units?.map((unit, index) => (
                      <Badge
                        key={index}
                        bg="badge-rounded"
                        className="badge-outline-dark px-4 py-1 h-100  fs-14 d-inline-flex gap-3 justify-content-between align-content-center "
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="c-pointer fs-18 h-100 d-inline-flex justify-content-center align-items-center"
                          onClick={() => {
                            setUnitName(unit.name);
                            setUnitType(unit.type);
                            setUnitDescription(unit.description);
                            setUnitIndex(index);
                            setModalCentered(true);
                          }}
                        >
                          {unit.name} - {unit.type}
                        </span>
                        <span
                          onClick={() => {
                            removeUnitByIndex(index, unit._id);
                          }}
                          variant=""
                          className=" btn-close p-0 m-0 fs-18 cursor-pointer  h-100 d-inline-flex justify-content-center align-items-center "
                        ></span>
                      </Badge>
                    ))}
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label className="form-label">Location</label>
                    <input
                      value={PropertyData?.location}
                      onChange={(e) => handleChange(e.target.value, "location")}
                      type="text"
                      className="form-control"
                      placeholder="Location of your property"
                    />
                  </div>

                  <div className="mb-3 col-6">
                    <label className="form-label">Location link</label>
                    <input
                      value={PropertyData?.location_link}
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          location_link: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Add link of your location from maps"
                    />
                  </div>

                  <div className="mb-3 col-sm-4">
                    <label className="form-label">City</label>
                    <input
                      value={PropertyData?.city}
                      className="form-control"
                      onChange={(e) => handleChange(e.target.value, "city")}
                    />
                  </div>

                  <div className="mb-3 col-sm-4">
                    <label className="form-label">Total value</label>
                    <input
                      value={
                        PropertyData.total_value
                          ? PropertyData.total_value
                          : null
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          total_value: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      placeholder="Total value of your property"
                    />
                  </div>
                  <div className="mb-3 col-sm-4">
                    <label className="form-label">Return percentage</label>
                    <input
                      value={
                        PropertyData.return_percentage
                          ? PropertyData.return_percentage
                          : null
                      }
                      onChange={(e) =>
                        setPropertyData({
                          ...PropertyData,
                          return_percentage: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      placeholder="Annual return percentage of your property"
                    />
                  </div>
                  <label className="form-label ">Images</label>

                  <div {...getRootProps()} className="drop-zone">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the images here...</p>
                    ) : (
                      <p>
                        Drag 'n' drop images here, or click to select images
                      </p>
                    )}
                  </div>

                  {files.length > 0 && (
                    <div>
                      <p>Previews:</p>
                      {files.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={file.url}
                            alt={`Preview ${index}`}
                            style={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                              opacity: file.isDeleted ? 0.5 : 1,
                            }}
                          />
                          <span
                            className={`${
                              file.isDeleted ? "text-success" : " text-danger"
                            }`}
                            onClick={() => removeImage(index)}
                            style={{
                              fontSize: "20px",
                              position: "absolute",
                              top: 0,
                              right: 8,
                              cursor: "pointer",
                            }}
                          >
                            {file.isDeleted ? "+" : "×"}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}

                  <label className="form-label pt-3">Documents</label>

                  <div className="mb-3 col-12">
                    <Button
                      className="me-2  "
                      variant="success btn-rounded"
                      onClick={() => {
                        setDocumentIndex(null);
                        setDocumentModalCentered(true);
                      }}
                    >
                      <span className="btn-icon-start text-success ">
                        <i className="fa fa-plus color-success " />
                      </span>
                      Add
                    </Button>
                  </div>

                  <div className="col-12 mb-3 ">
                    {documents?.map((doc, index) => (
                      <Badge
                        key={index}
                        bg="badge-rounded"
                        className="badge-outline-dark px-4 py-1 h-100 fs-14 d-inline-flex gap-3 justify-content-between align-content-center "
                      >
                        <span
                          className="c-pointer fs-18 h-100 d-inline-flex justify-content-center align-items-center"
                          onClick={() => {
                            setDocumentName(doc.label);
                            setDocumentDescription(doc.description);
                            setDocumentFile(doc.document_link);
                            setDocumentIndex(index);
                            setDocumentModalCentered(true);
                          }}
                        >
                          {doc.label}
                        </span>
                        <span
                          onClick={() => removeDocumentByIndex(index, doc._id)}
                          variant=" "
                          className=" btn-close p-0 m-0 fs-18 cursor-pointer  h-100 d-inline-flex justify-content-center align-items-center "
                        ></span>
                      </Badge>
                    ))}
                  </div>
                </div>
              </form>

              <form>
                <div className="row">
                  <div className="col-sm-12 pt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-2"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger light"
                      onClick={() => nav("/property-list")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPropertyDetails;
