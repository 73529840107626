/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import "react-medium-image-zoom/dist/styles.css";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { getPropertyInitialExpense } from "../../../services/PropertyService";

function InitialTransactions() {
  const [initialTransactionList, setInitialTransactionsList] = useState([]);
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];

  const [totalDocuments, setTotalDocuments] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalDocuments / itemsPerPage);

  useEffect(() => {
    setIsLoading(true);
    getPropertyInitialExpense(propertyId, pageNo)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        setInitialTransactionsList(res?.data?.data);
        setTotalDocuments(res?.data?.total_documents);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [pageNo]);

  const handlePageChange = (isPrevious) => {
    setPageNo((prev) =>
      isPrevious ? Math.max(prev - 1, 1) : Math.min(prev + 1, totalPages)
    );
    console.log(pageNo);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row h-100">
        <div className="col-xl-12 h-100 gap-4">
          <h3 className="text-center">Initial Transactions</h3>

          <div className="table-responsive fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th className="">Billing Date</th>
                    <th className="">Posting Date</th>
                    <th className="">Added by</th>
                    <th className="">Voucher no.</th>
                    <th className="">Amount</th>
                    <th className="">Type</th>
                    <th className="">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {initialTransactionList.length > 0 ? (
                    initialTransactionList.map((item, index) => (
                      <tr key={index}>
                        <td>{new Date(item?.billing_date).toUTCString()}</td>
                        <td>{new Date(item?.posting_date).toUTCString()}</td>

                        <td>
                          {item?.user?.name
                            ? item?.user?.name
                            : "Not Available"}
                        </td>
                        <td>{item?.voucher_number}</td>
                        <td>{item?.amount}</td>
                        <td>
                          <Badge
                            bg=""
                            className={`badge-${
                              item?.type === "expense" ? "warning" : "green"
                            } badge-xs`}
                          >
                            {item?.type === "expense" ? "Expense" : "Income"}
                          </Badge>
                        </td>
                        <td>{item?.description}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Transactions</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-5">
                <div className="dataTables_info">
                  Showing {initialTransactionList.length} of {totalDocuments}{" "}
                  entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="paginate_button previous"
                    to="#"
                    onClick={() => {
                      if (pageNo > 1) {
                        handlePageChange(true);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-left" />
                  </div>

                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <div
                        key={i}
                        // to="#"
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => setPageNo(i + 1)}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </span>
                  <div
                    className="paginate_button next"
                    onClick={() => {
                      if (pageNo < totalPages) {
                        handlePageChange(false);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InitialTransactions;
