import { getAllUsers } from "../../services/UsersServices";

export const UPDATE_USERS_LIST_DATA = "[UPDATE_USERS_LIST_DATA]";
export const REQUEST_START = "REQUEST_START";

export function UsersListAction(sortBy) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getAllUsers(sortBy)
      .then((response) => {
        dispatch(updateUsersListData(response.data));
        // console.log(response.data);
      })
      .catch((error) => {});
  };
}

export function updateUsersListData(data) {
  return {
    type: UPDATE_USERS_LIST_DATA,
    payload: data,
  };
}
