import React, { useState } from "react";

export function PropertyRentComponent({ PropertyRentData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 6;

  // console.log(PropertyRentData);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = PropertyRentData?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(PropertyRentData?.length / dataPerPage);

  return (
    <div className="col-12">
      <div className="card-header mb-0 border-0 pb-0 d-flex justify-content-between align-items-center">
        <h3 className="fs-20 text-black mb-0">Rent Collection</h3>
      </div>
      <div className="row card-body">
        {PropertyRentData?.length > 0 ? (
          <div className="table-responsive table-hover fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th className="">Name</th>
                    <th className="">Billing Date</th>
                    <th className="">Posting Date</th>
                    <th className="">Amount</th>
                    <th className="">Description</th>
                    <th className="">Type</th>
                    <th className="sorting">Room</th>
                    {/* <th className="">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.user?.name}</td>
                      <td>
                        {item?.billing_date
                          ? new Date(item.billing_date).toUTCString()
                          : ""}
                      </td>
                      <td>
                        {item?.billing_date
                          ? new Date(item.posting_date).toUTCString()
                          : ""}
                      </td>
                      <td> {item?.amount}</td>
                      <td>{item?.description}</td>
                      <td>{item.type}</td>
                      <td>{item.units?.length > 0 ? item.units?.length : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {currentData.length} of {PropertyRentData.length}{" "}
                  entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="paginate_button previous"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i className="fa fa-angle-double-left" />
                  </div>
                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <div
                        key={i}
                        className={`paginate_button ${
                          currentPage === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          if (currentPage > 1) {
                            handlePageChange(i + 1);
                          }
                        }}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </span>
                  <div
                    className="paginate_button next"
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                      }
                    }}
                    disabled={currentPage === totalPages}
                  >
                    <i className="fa fa-angle-double-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-12">
            <div className="h-100">
              <div
                className={`card-body d-flex justify-content-center align-items-center h-100`}
              >
                <p className="d-flex justify-content-center align-items-center h-100">
                  No Data Found.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
